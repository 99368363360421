import { crowdsaleModel } from 'entities/crowdsale';
import { modalModel } from 'entities/modal';
import { userModel } from 'entities/user';
import {
  Chains,
  ContractsNames,
  ContractsService,
  createAppAsyncThunk,
  logger,
  mapChainToBlockExplorerUrl,
  toDecimals,
} from 'shared';

import { actionTypes } from '../action-types';
import { BuyTokensArgs } from '../types';

import { approve } from './approve';

export const buyTokens = createAppAsyncThunk(
  actionTypes.BUY_TOKENS,
  async (
    { web3Provider, referrerAddress, packId, stablesAmount, stableCoinAddress }: BuyTokensArgs,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      dispatch(
        crowdsaleModel.actions.updateBuyData({
          referrerAddress,
          stablesAmount,
          stableCoinAddress,
          packId,
        }),
      );

      const contractService = new ContractsService(web3Provider);
      const { chainType, address } = userModel.selectors.selectUserWeb3Info(getState());

      const blockExplorer = mapChainToBlockExplorerUrl[Chains.BSC][chainType];

      const { address: crowdsaleAddress } = ContractsService.getContractData(
        ContractsNames.Crowdsale,
        Chains.BSC,
        chainType,
      );

      const tokenAmountWithDecimals = toDecimals(stablesAmount, 18);

      try {
        await dispatch(
          approve({
            web3Provider,
            stableCoinAddress,
            amount: tokenAmountWithDecimals,
            spender: crowdsaleAddress,
          }),
        ).unwrap();
      } catch (err) {
        return rejectWithValue(err);
      }

      dispatch(
        modalModel.actions.openModal({
          type: modalModel.ModalType.Send,
          transition: 'fade',
        }),
      );

      const crowdsaleContract = contractService.getCrowdsaleContract(chainType);
      const gasPrice: string = await web3Provider.eth.getGasPrice();

      const { transactionHash } = await crowdsaleContract.methods
        .buyCratTokens(stableCoinAddress, 0, referrerAddress, 0)
        .send({
          from: address,
          gasPrice,
        });

      await dispatch(
        userModel.thunks.getUserInfo({
          web3Provider,
        }),
      );

      dispatch(
        modalModel.actions.openModal({
          type: modalModel.ModalType.SendSuccess,
          data: {
            transactionLink: `${blockExplorer}/tx/${transactionHash}`,
          },
          transition: 'fade',
        }),
      );

      return transactionHash;
    } catch (err) {
      dispatch(
        modalModel.actions.openModal({
          type: modalModel.ModalType.SendReject,
          transition: 'fade',
        }),
      );

      logger('buyTokens', err);
      return rejectWithValue(err);
    }
  },
);
