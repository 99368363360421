import { userModel } from 'entities/user';
import { ContractsService, createAppAsyncThunk, logger, RequestWithWeb3Provider } from 'shared';

import { actionTypes } from '../action-types';

export const getCratPrice = createAppAsyncThunk(
  actionTypes.GET_CRAT_PRICE,
  async ({ web3Provider }: RequestWithWeb3Provider, { rejectWithValue, getState }) => {
    try {
      const contractService = new ContractsService(web3Provider);
      const { chainType } = userModel.selectors.selectUserWeb3Info(getState());
      const crowdsaleContract = contractService.getCrowdsaleContract(chainType);
      return await crowdsaleContract.methods.currentTokenPrice().call();
    } catch (err) {
      logger('getCratPrice', err);
      return rejectWithValue(err);
    }
  },
);
