import { CratLogo } from 'shared/assets';

import { crowdsaleAbi, erc20Abi } from '../abi';
import { Chains, ContractsNames, IContracts } from '../types';

export const contracts: IContracts['contracts'] = {
  [ContractsNames.Crat]: {
    testnet: {
      address: {
        [Chains.BSC]: '0xba3B9D3A521c5A71fc26182F77001248C88dA774',
      },
      abi: erc20Abi,
      symbol: 'CratD2C-Pre',
      decimals: 0,
      img: CratLogo,
    },
    mainnet: {
      address: {
        [Chains.BSC]: '0x3953E03D3cc3972BE18B6C15EA4d324d28B32a8C',
      },
      abi: erc20Abi,
      symbol: 'CratD2C-Pre',
      decimals: 0,
      img: CratLogo,
    },
  },

  [ContractsNames.Crowdsale]: {
    testnet: {
      address: {
        [Chains.BSC]: '0xC78BCc544117dc36d7368cc6F569A374c56a750A',
      },
      abi: crowdsaleAbi,
    },
    mainnet: {
      address: {
        [Chains.BSC]: '0x5B71088F0CAb5092d97956557f7562f8De4E6E77',
      },
      abi: crowdsaleAbi,
    },
  },

  [ContractsNames.USDT]: {
    testnet: {
      address: {
        [Chains.BSC]: '0x66fd91C06C4adB7c411324BC54d4AF89a6fE585a',
      },
      abi: erc20Abi,
    },
    mainnet: {
      address: {
        [Chains.BSC]: '0x55d398326f99059fF775485246999027B3197955',
      },
      abi: erc20Abi,
    },
  },
  // BUSD
  [ContractsNames.USDC]: {
    testnet: {
      address: {
        [Chains.BSC]: '0xE1B2A624147Ceb1154D132DFb3885a621DA165B7',
      },
      abi: erc20Abi,
    },
    mainnet: {
      address: {
        [Chains.BSC]: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      },
      abi: erc20Abi,
    },
  },
};

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts,
};
