import { useCallback } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { crowdsaleModel } from 'entities/crowdsale';
import {
  BORDER_RADIUS_L,
  COLOR_ACCENT,
  COLOR_STROKE_SECONDARY,
  fromDecimals,
  PRE_IEO_URL,
  useAppDispatch,
  useIntervalAsync,
  useShallowSelector,
} from 'shared';
import { useWalletConnectorContext } from 'shared/config/wallet-connect/hooks/useWeb3Provider';

import IEOActivateBg from './assets/crowdsale_bg.png';

const IeoActivateTitle = () => {
  const price = useShallowSelector(crowdsaleModel.selectors.getProp('cratPrice'));

  return (
    <Typography
      variant="h4"
      textTransform="uppercase"
      className="large semi-bold center"
      sx={{ span: { color: COLOR_ACCENT } }}
    >
      <span>Activate</span> IEO private placement <span>${fromDecimals(price)}</span>
    </Typography>
  );
};

export const IeoActivate = () => {
  const dispatch = useAppDispatch();
  const { walletService } = useWalletConnectorContext();

  const getCratPrice = useCallback(async () => {
    dispatch(crowdsaleModel.thunks.getCratPrice({ web3Provider: walletService.Web3() }));
  }, [dispatch, walletService]);

  useIntervalAsync(getCratPrice, 20_000);

  return (
    <Stack
      component="section"
      position="relative"
      overflow="hidden"
      mt={{ xs: 6, sm: 12 }}
      py={{ xs: 8, md: 9.25 }}
      border={`1px solid ${COLOR_STROKE_SECONDARY}`}
      borderRadius={BORDER_RADIUS_L}
      p={{ xs: 2, sm: 4 }}
      alignItems="center"
      spacing={4}
      sx={{
        backgroundImage: `url(${IEOActivateBg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <IeoActivateTitle />
      <Button
        LinkComponent="a"
        href={PRE_IEO_URL}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ width: 170, fontWeight: 700, textTransform: 'uppercase', letterSpacing: '-0.03em' }}
      >
        Activate
      </Button>
    </Stack>
  );
};
